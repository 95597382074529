.btn-primary {
  font-size: 14px !important;
  color: $white-color !important;
  background-color: $primary-color !important;
  border-color: $primary-color !important;

  &:hover {
    color: $white-color !important;
    background-color: $primary-color !important;
    border-color: $primary-color !important;
  }

  &:disabled {
    color: $white-color;
    background-color: $primary-color;
    border-color: $primary-color;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-warn {
  font-size: 14px !important;
  color: $black !important;
  background-color: $yellow-color !important;
  border-color: $yellow-color !important;

  &:hover {
    color: $black !important;
    background-color: $yellow-color !important;
    border-color: $yellow-color !important;
  }

  &:disabled {
    color: $black !important;
    background-color: $yellow-color !important;
    border-color: $yellow-color !important;
  }
}

.btn-danger {
  font-size: 14px !important;
  color: $white-color !important;
  background-color: $red-color !important;;
  border-color: $red-color !important;;

  &:hover {
    color: $white-color !important;
    background-color: $red-color !important;
    border-color: $red-color !important;
  }

  &:disabled {
    color: $white-color !important;
    background-color: $red-color !important;
    border-color: $red-color !important;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-success {
  font-size: 14px !important;
  background-color: $green-color;
  border-color: $green-color;
  color: $white-color !important;

  &:hover {
    background-color: $green-color;
    border-color: $green-color;
    color: $white-color !important;
  }

  &:disabled {
    background-color: $green-color;
    border-color: $green-color;
    color: $white-color !important;
  }

  &:focus {
    box-shadow: none;
  }
}

.mark-all-read-icon {
  color: $primary-color;
  font-size: 20px;
}

.btn-secondary {
  font-size: 14px !important;
  color: $white-color !important;
  background-color: $gray-color !important;
  border-color: $gray-color !important;

  &:hover {
    color: $white-color !important;
    background-color: $gray-color !important;
    border-color: $gray-color !important;
  }

  &:disabled {
    color: $white-color;
    background-color: $gray-color;
    border-color: $gray-color;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-xs {
  font-size: 13px !important;
}


