//@use '@angular/material' as mat;
@import "./styles/main";

@import "~bootstrap/scss/bootstrap";
@import 'node_modules/ngx-toastr/toastr-bs4-alert';
// @import "~ng-pick-datetime/assets/style/picker.min.css";
@import "./app/shared/scss/_variable.scss";
@import "./app/shared/scss/_responsive.scss";
@import "~@bryntum/calendar/calendar.stockholm.css";

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(assets/fonts/Open_Sans/Open-Sans-normal-300.woff) format('woff'), url(assets/fonts/Open_Sans/Open-Sans-normal-300.woff2) format('woff2'), url(assets/fonts/Open_Sans/Open-Sans-normal-300.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/Open_Sans/Open-Sans-normal-400.eot);
  src: local('Open Sans'), url(assets/fonts/Open_Sans/Open-Sans-normal-400.woff) format('woff'), url(assets/fonts/Open_Sans/Open-Sans-normal-400.woff2) format('woff2'), url(assets/fonts/Open_Sans/Open-Sans-normal-400.eot?#iefix) format('embedded-opentype'), url(assets/fonts/Open_Sans/Open-Sans-normal-400.svg#OpenSans) format('svg'), url(assets/fonts/Open_Sans/Open-Sans-normal-400.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url(assets/fonts/Open_Sans/Open-Sans-normal-600.woff) format('woff'), url(assets/fonts/Open_Sans/Open-Sans-normal-600.woff2) format('woff2'), url(assets/fonts/Open_Sans/Open-Sans-normal-600.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url(assets/fonts/Open_Sans/Open-Sans-normal-700.woff) format('woff'), url(assets/fonts/Open_Sans/Open-Sans-normal-700.woff2) format('woff2'), url(assets/fonts/Open_Sans/Open-Sans-normal-700.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url(assets/fonts/Open_Sans/Open-Sans-normal-800.woff) format('woff'), url(assets/fonts/Open_Sans/Open-Sans-normal-800.woff2) format('woff2'), url(assets/fonts/Open_Sans/Open-Sans-normal-800.ttf) format('truetype');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/Material_Icons/Material-Icons-normal-400.eot);
  src: local('Material Icons'), url(assets/fonts/Material_Icons/Material-Icons-normal-400.woff) format('woff'), url(assets/fonts/Material_Icons/Material-Icons-normal-400.ttf) format('truetype'), url(assets/fonts/Material_Icons/Material-Icons-normal-400.woff2) format('woff2'), url(assets/fonts/Material_Icons/Material-Icons-normal-400.eot?#iefix) format('embedded-opentype'), url(assets/fonts/Material_Icons/Material-Icons-normal-400.svg#MaterialIcons) format('svg');
}

// html,
body {
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  // -webkit-transform:translateZ(0.1px);
  //transform: translateZ(0.1px); /* needs an actual value */
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  color: $primary-color !important;
  background-color: #ebf1fb;
  font-size: 14px !important;
}

.file-upload-button {
  font-size: 14px;
  cursor: pointer;
}

.ng-select input {
  /*padding-top: 7px;*/
}

.ng-placeholder {
  top: 7px !important;
}

.btn-success {
  color: white;
  background-color: $green-color;
  border-color: $green-color;
  height: 39px;
}

.btn-success:hover {
  color: white;
  background-color: $lightGreen-color;
  border-color: $lightGreen-color;
  height: 39px;
}

.dropdown-item {
  color: $primary-color !important;
  text-decoration: none;
}

.cal-month-view .cal-cell-top {
  min-height: 60px !important;
}

.list-shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09) !important;
}

.ng-placeholder {
  font-size: 14px;
}

.alert-info {
  background-color: $adminBlue;
}

.iti {
  // display: block !important;
  width: 100%;
}

.iti .iti__country-list {
  position: relative !important;
  border: none !important;
  box-shadow: none !important;
}

.iti .iti__country-list .iti__flag-box,
.iti .iti__country-list .iti__country-name {
  font-size: 14px !important;
}

.iti .iti__country-list .iti__country .iti__dial-code {
  font-size: 14px !important;
}

.iti.separate-dial-code .selected-dial-code {
  font-size: 14px !important;
}

.iti {
  #country-search-box {
    padding-left: 6px !important;
    height: 40px !important;
    font-size: 14px !important;
  }
}

/*.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05) !important;
}*/

.toast-error {
  background-color: #ef6565 !important;
}

.toast-success {
  background-color: #97cc2e !important;
}

button:focus {
  outline: 0px;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
}

:focus {
  outline: 0px;
}

.btn-danger {
  color: $white-color !important;
  background-color: $red-color !important;
  border-color: $red-color !important;
}

.toast-container .toast {
  box-shadow: 0 0 4px #999999 !important;
}

.toast-container .toast:hover {
  box-shadow: none !important;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  font-family: "Open Sans", sans-serif !important;
}

button {
  font-size: 14px !important;
}

button:focus {
  box-shadow: none !important;
}

.form-control {
  font-size: 14px !important;
}

.form-control:focus {
  border-color: #cccccc !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

label {
  font-size: 14px;
  font-weight: 600;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container {
  .ng-value-container {
    .ng-value {
      font-size: 14px;
      line-height: 1.5;
      color: #495057;
    }

    .ng-placeholder {
      font-size: 14px;
      line-height: 1.5;
      color: #6d757c;
    }
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: 14px !important;
  background-color: #f5faff !important;
  border-radius: 2px !important;
  /* border: 1px solid #c2e0ff !important; */
  padding: 0px 6px 0px 0px !important;
  /* white-space: nowrap !important; */
  border: 0.5px solid #c2e0ff !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: inline-block !important;
  padding: 1px 5px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 14px;
  line-height: 1.5;
  color: #495057;
}

#toast-container > div {
  opacity: 1;
}

a {
  font-size: 14px;
  color: #495057;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $green-color !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: $green-color !important;
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: $green-color !important;
}

.mat-row {
  vertical-align: top;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

.cal-month-view .cal-cell-row:hover {
  background-color: transparent !important;
}

.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: transparent !important;
}

.cal-month-view .cal-header .cal-cell {
  font-weight: 500;
}

.cal-month-view .cal-days {
  border-color: transparent !important;
}

.cal-month-view .cal-days .cal-cell-row {
  border-color: transparent !important;
}

.cal-month-view .cal-day-cell:not(:last-child) {
  border-color: transparent !important;
}

.cal-month-view .cal-cell {
  align-items: center !important;
}

.cal-month-view .cal-day-number {
  font-size: 14px !important;
  font-weight: 500 !important;
  opacity: 1 !important;
  margin-top: 15px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 8px;
}

.cancel-button {
  margin-right: 4px;
  height: 40px;
}

.cal-month-view .cal-day-cell.cal-today {
  background-color: transparent !important;
}

.cal-days {
  margin-top: 15px !important;
}

.cal-month-view .cal-day-cell {
  min-height: 0px !important;
}

.cal-month-view {
  padding-top: 20px !important;
}

.cal-disabled {
  color: #e1e1e1 !important;
}

.cal-day-number {
  cursor: pointer !important;
}

.cal-selected {
  .cal-day-number {
    width: 42px;
    height: 42px;
    color: white !important;
    background-color: $primary-color;
    border-radius: 10px;
  }
}

.cal-month-view .cal-cell-top {
  min-height: 60px;
}

.custom-select-field {
  .ng-dropdown-panel {
    border-left: 2px solid #ccc;
  }
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #ef6565;
  // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

.ng-select-focused {
  outline: 0px !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: #ccc !important;
  box-shadow: none !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.rocket-box .progressbar .progress {
  border-radius: 7px;
  border: 2px solid #79b0f8;
  background-color: white;
  // border-image: linear-gradient(#7974f7, #77b1f8) 1 1 round;
}

.rocket-box .progressbar .limit-exceeded {
  border: 2px solid $calRed;
}

.rocket-box .progressbar .progress-bar {
  background-color: #7974f7;
  background-image: linear-gradient(to right, #7974f7, #77b1f8);
  font-size: 14px;
}

.rocket-box .progressbar .limit-exceeded .progress-bar {
  background-color: $red-color;
  background-image: linear-gradient(to right, $red-color, $red-color);
  color: white;
  font-size: 14px;
}

.event-progressbar .progress {
  border-radius: 7px;
  border: 2px solid #79b0f8;
  background-color: white;
  // border-image: linear-gradient(#7974f7, #77b1f8) 1 1 round;
}

.event-progressbar .progress-bar {
  background-color: #7974f7;
  background-image: linear-gradient(to right, #7974f7, #77b1f8);
  font-size: 14px;
}

.progress-bar-red .progress-bar {
  background-color: $yellow-color !important;
  background-image: linear-gradient(to right, $yellow-color, $red-color) !important;
  font-size: 14px !important;
}

.inner-html-box {
  a {
    text-decoration: none;
    color: #68d6f0;
  }
}

.customer-note-bottom-sheet {
  color: $primary-color;
}

.customer-note-ng-select {
  .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 150px;
  }
}

@media (min-width: 992px) {
  .calio-body {
    margin-top: 140px;
  }

  .sticky-header {
    .calio-header {
      width: 100%;
      position: absolute;
      box-shadow: none;
      -webkit-box-shadow: 0px 18px 56px -10px rgba(30, 30, 30, 0.2);
      -moz-box-shadow: 0px 18px 56px -10px rgba(30, 30, 30, 0.2);
      box-shadow: 0px 18px 56px -10px rgba(30, 30, 30, 0.2);

      -webkit-transition: all 0.5s ease;
      -moz-transition: position 10s;
      -ms-transition: position 10s;
      -o-transition: position 10s;
      transition: all 0.5s ease;

      &.sticky {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 1000;
        animation: smoothScroll 1s both;
        // box-shadow: 0 4px 5px -5px mat.get-color-from-palette($accent, 300);
      }
    }
  }
}

@media (max-width: 991px) {

  .calio-body {
    margin-top: 0px !important;
  }

  .sticky-header .calio-header.sticky {
    //overflow-y: auto;
    // height: 100vh;
  }
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

.hover-card:hover {
  box-shadow: 0px 0px 64px -26px rgba(0, 0, 0, 0.5) !important;
}

.card {
  border: none !important;
  border-radius: 8px !important;

  .divider {
    margin-left: -15px;
    margin-right: -15px;
  }

  .card-body {
    padding-bottom: 20px !important;
  }

  @media (max-width: 500px) {
    .divider {
      // margin-left: 0px !important;
      // margin-right: 0px !important;
    }
  }
}

.skeleton-animation {
  position: relative;
  overflow: hidden;

  .bar {
    background-color: #e7e7e7;
    height: 14px;
    border-radius: 7px;
    width: 100%;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0) 80%
    );
    background-size: 200px 100%;
    background-position: -100px 0;
    background-repeat: no-repeat;
    animation-name: loading;
    animation-duration: 5s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    // animation: loading 5.05s infinite;
  }
}

@keyframes loading {
  40% {
    background-position: 100% 0;
  }

  100% {
    background-position: 120% 0;
  }
}

/* Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .customer-note-bottom-sheet {
    min-width: 100vw !important;
  }
}

/* Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  // .customer-note-bottom-sheet {
  //   min-width: 100vw !important;
  // }
}

.calenso-widget-modal {
  // top: 120px !important;

  .modal-dialog {
    min-height: 1200px !important;
  }
}

html.cdk-global-scrollblock {
  //  position: initial !important;
  top: 0px !important;
}

html.cdk-global-scrollblock body {
  // position: fixed;
}

.card-separator {
  margin: 0px -10px 10px -10px !important;
}

.card-title {
  font-size: 18px;
  font-weight: 600;
}

.event-time-slot-bottom-sheet {
  // width: 540px;
  /*  .repeating-dates-block {
      width: 100% !important;
    }

    .repeating-days-block {
      width: 90% !important;
    }*/
}

/* table and search */
.primary-title {
  font-size: 22px;
  letter-spacing: 0.2px;
  font-weight: 600;
}

.sub-title {
  font-size: 16px;
  color: $gray-color;
  letter-spacing: 0.3px;
}

.search-box-block {
  width: 30%;

  .search-box {
    // margin-top: 15px;
    // width: 350px;
    // max-width: 350px;
    // min-width: 290px;

    input {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      height: 40px;
      box-shadow: 0 00.2rem 1rem rgba(0, 0, 0, 0.15) !important;
      border: none;
    }

    input:focus {
      box-shadow: 0 00.2rem 1rem rgba(0, 0, 0, 0.15) !important;
    }

    .search-icon {
      background-color: $primary-color;
      color: $white-color;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(246, 247, 250, 1);
}

.table {
  color: $primary-color;
  margin-top: 20px;

  thead {
    line-height: 30px;

    th {
      border-bottom: 0px;
      border-top: 0px;
    }
  }
}

.mat-table {
  width: 100%;
  color: $primary-color;
  box-shadow: none;
  font-size: 14px;

  .table-edit-icon {
    display: flex;
    justify-content: flex-end;
  }

  tbody tr:nth-of-type(odd) {
    background-color: rgba(246, 247, 250, 1);
  }

  .mat-header-cell {
    color: $primary-color;
    font-size: 13px !important;
    font-weight: 600 !important;
    border-bottom: none;
    min-width: 100px;
  }

  .mat-cell {
    color: $primary-color;
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.white-button {
  color: $primary-color !important;
  background-color: $white-color !important;
  border-color: $primary-color !important;

  &:hover {
    color: $primary-color !important;
    background-color: $white-color !important;
    border-color: $primary-color !important;
  }
}

.save-button {
  color: $white-color !important;
  background-color: $primary-color !important;
  border-color: $primary-color !important;

  &:hover {
    color: $white-color !important;
    background-color: $primary-color !important;
    border-color: $primary-color !important;
  }
}

.create-button {
  color: $white-color !important;
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  height: 40px !important;

  &:hover {
    color: $white-color !important;
    background-color: $primary-color !important;
    border-color: $primary-color !important;
  }
}

.yellow-button {
  color: $black !important;
  background-color: $yellow-color !important;
  border-color: $yellow-color !important;
  height: 40px !important;

  &:hover {
    color: $black !important;
    background-color: $yellow-color !important;
    border-color: $yellow-color !important;
  }
}

.cancel-button {
  margin-left: 4px;
  color: $white-color !important;
  background-color: $red-color !important;
  border-color: $red-color !important;
}

.e-footer-content {
  display: none !important;
}

.dropdown-toggle::after {
  display: none;
}

a,
a:visited,
a:hover {
  color: $violet-color;
  text-decoration: none;
}

.custom-link,
.custom-link:visited,
.custom-link:hover,
.href-link,
.href-link:visited,
.href-link:hover {
  color: $primary-color;
  text-decoration: none;
}

.mat-drawer-container {
  overflow: inherit !important;
  color: $primary-color !important;
}

.mat-drawer-content {
  background: $light-sky-blue !important;
  overflow: inherit !important;
}

.event-progressbar .progres-bar-red .progress-bar {
  background-color: $red-color;
  background-image: linear-gradient(to right, $red-color, $red-color);
  font-size: 14px;
}

.event-progressbar .progres-bar-red .progress {
  border: 2px solid $calRed;
}

.dropdown-item {
  font-size: 14px;
  color: $primary-color;
}

.calenso-add-btn {
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}

.calenso-edit-btn {
  height: 18px;
  width: 19px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.event-participant-bottom-sheet {
  overflow-x: hidden !important;
}

.load-more-button,
.load-more-button:hover,
.load-more-button:active {
  color: $white-color !important;
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

// .load-more-button:hover {
//   color: $white-color;
//   background-color: $primary-color;
//   border-color: $primary-color;
// }
.modal {
  z-index: 10000;
}

.dropdown-item:hover,
.dropdown-item:focus {
  outline: none;
}

.dropdown-item.active,
.dropdown-item:active {
  color: $primary-color !important;
  background-color: inherit;
}

.mat-table {
  font-family: "Open Sans", sans-serif !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $primary-color !important;
}

.mat-checkbox-layout {
  white-space: inherit !important;
  margin-bottom: 4px !important;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 16px !important;
  font-family: "Open Sans", sans-serif !important;
}

.mat-slide-toggle-label {
  font-family: "Open Sans", sans-serif !important;
}

.mat-slide-toggle-content {
  font-family: "Open Sans", sans-serif !important;
}

/*.mat-dialog-container {
  color: $primary-color !important;
}*/

.action-button-delete-block {
  .dropdown-item {
    //padding: 0.25rem 1rem !important;
  }
}

.shadow {
  -webkit-box-shadow: 0px 0px 64px -26px rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0px 0px 64px -26px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0px 0px 64px -26px rgba(0, 0, 0, 0.2) !important;
}

.cal-default {
  background-color: $defaultAppointmentBg !important;
  border-color: $defaultAppointment !important;
  color: $defaultAppointment !important;

  &:hover,
  &:focus {
    //background: $adminBlue !important;
    //color: $white !important;
  }
}

.cal-default.b-sch-event.b-sch-event-selected * {
  color: $defaultAppointment !important;
}

.cal-blue {
  background-color: $defaultAppointmentBg !important;
  border-color: $defaultAppointment !important;
  color: $defaultAppointment !important;

  &:hover,
  &:focus {
    //background: $adminBlueLight !important;
  }
}

.cal-blue.b-sch-event.b-sch-event-selected * {
  color: $defaultAppointment !important;
}

.cal-violett {
  background-color: $eventBlockerBg !important;
  border-color: $eventBlocker !important;
  color: $eventBlocker !important;

  &:hover,
  &:focus,
  .dropdown-toggle {
    //background: $petrol !important ;
  }
}

.cal-violett.b-sch-event.b-sch-event-selected * {
  color: $eventBlocker !important;
}

.cal-yellow {
  background-color: $calYellowBg !important;
  border-color: $calYellow !important;
  color: $calYellow !important;

  &:hover,
  &:focus,
  .dropdown-toggle {
    //background: $grey !important ;
  }
}

.cal-yellow.b-sch-event.b-sch-event-selected * {
  color: $calYellow !important;
}

.cal-orange {
  background-color: $calOrangeBg !important;
  border-color: $calOrange !important;
  color: $calOrange !important;

  &:hover,
  &:focus,
  .dropdown-toggle {
    //background: $gold !important ;
  }
}

.cal-orange.b-sch-event.b-sch-event-selected * {
  color: $calOrange !important;
}

.cal-red {
  background-color: $calRedBg !important;
  border-color: $calRed !important;
  color: $calRed !important;

  &:hover,
  &:focus,
  .dropdown-toggle {
    //background: $gold !important ;
  }
}

.cal-red.b-sch-event.b-sch-event-selected * {
  color: $calRed !important;
}

.cal-green {
  background-color: $calGreenBg !important;
  border-color: $calGreen !important;
  color: $calGreen !important;

  &:hover,
  &:focus,
  .dropdown-toggle {
    //background: $gold !important ;
  }
}

.cal-green.b-sch-event.b-sch-event-selected * {
  color: $calGreen !important;
}

.cal-vacation {
  background: repeating-linear-gradient(
      45deg,
      rgba(155, 155, 155, 0.5),
      rgba(155, 155, 155, 0.5) 8px,
      transparent 8px,
      transparent 16px
  ) !important;
  color: #666 !important;
  background-color: transparent !important;
  border-color: $extCalGrey !important;

  &:hover,
  &:focus,
  &:active {
    background: repeating-linear-gradient(
        45deg,
        rgba(155, 155, 155, 0.5),
        rgba(155, 155, 155, 0.5) 8px,
        transparent 8px,
        transparent 16px
    ) !important;
    color: #666 !important;
    background-color: transparent !important;
    border-color: $extCalGrey !important;
  }
}

.cal-vacation.b-sch-event.b-sch-event-selected * {
  color: #666 !important;
}

// External social icons

/* External Calendars */
.cal-google {
  background-color: $extCalGreyBg !important;
  border-color: $extCalGrey !important;
  color: $extCalGrey !important;
  //background-color: rgba(251, 188, 5, 0.3);
}

.cal-icloud {
  background-color: $extCalGreyBg !important;
  border-color: $extCalGrey !important;
  color: $extCalGrey !important;
  //background-color: rgba(109, 110, 113, 0.3);
}

.cal-office365 {
  background-color: $extCalGreyBg !important;
  border-color: $extCalGrey !important;
  color: $extCalGrey !important;
  //background-color: rgba(220, 55, 1, 0.3);
}

.cal-exchange {
  background-color: $extCalGreyBg !important;
  border-color: $extCalGrey !important;
  color: $extCalGrey !important;
  //background-color: rgba(0, 144, 198, 0.3) !important;
}

.cal-external {
  background-color: $extCalGreyBg !important;
  border-color: $extCalGrey !important;
  color: $extCalGrey !important;
}

// TOOLTIP
.b-sch-event.b-sch-event-wrap.b-sch-style-plain {
  border-right: 1px solid #fff;
}

.b-sch-event .name,
.b-sch-event-selected .name {
  margin-left: 0.4em !important;
  width: 100% !important;
  font-weight: 600 !important;
  font-family: "Lato", sans-serif !important;
  padding-top: 2px;
}

.b-sch-event .customer-name,
.b-sch-event .services,
.b-sch-event-selected .customer-name,
.b-sch-event-selected .services {
  font-weight: 400 !important;
  font-family: "Lato", sans-serif !important;
}

.b-sch-event .time,
.b-sch-event-selected .time {
  text-align: right !important;
  font-weight: 600 !important;
}

.b-sch-event-tooltip {
  min-width: 190px;
}

.b-tooltip-content {
  background: white !important;
  color: $primary-color;
}

.b-sch-clock-text {
  color: $primary-color !important;
}

button.b-button.b-raised.b-blue,
button.b-raised.b-button,
button.b-widget.b-button.b-raised {
  color: #fff;
  border: none;
  background-color: $violet-color;
}

button.b-button.b-raised.b-blue,
button.b-raised.b-button,
button.b-widget.b-button.b-raised {
  color: #fff;
  border: none;
  background-color: $violet-color !important;
}

.b-sch-event-tooltip .b-tooltip-header {
  background-color: $violet-color !important;
}

.b-blue.b-raised {
  background-color: $violet-color !important;
}

// .b-sch-event-tooltip dl,
// .b-sch-event-tooltip dt,
// .b-sch-event-tooltip dd {
//   padding: 0;
//   margin: 0;
// }

// .b-sch-event-tooltip dd {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   margin-top: 0.3em;
//   line-height: 1em;
// }

// .b-sch-event-tooltip dt {
//   font-weight: bold;
// }

// .b-sch-event-tooltip dt:not(:first-child) {
//   margin-top: 1em;
// }

// .b-sch-event-tooltip dd .image {
//   height: 250px;
// }

// @media (max-height: 800px) {
//   .b-sch-event-tooltip dd .image {
//     height: 150px;
//   }
// }

// @media (max-width: 320px) {
//   .b-sch-event-tooltip dd .image {
//     height: 100px;
//   }
// }

header.scheduler-header {
  background-color: $primary-color;
}

.b-sch-header-timeaxis-cell {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  color: #757575;
  border-bottom: 1px solid transparent;
  border-right: 1px solid transparent;
  overflow: hidden;
  -webkit-transition: background-color .2s;
  transition: background-color .2s;
  font-size: 1em;
  font-weight: 600;
  padding: .5em 0 !important;
  white-space: nowrap;
  contain: strict;
  user-select: none;
}

.b-resourceheader-cell {
  font-weight: 600;
}

.b-sch-resource-time-range {
  font-size: 12px;
}

//horizontal scheduler
.b-scheduler {
  .b-sch-event {
    .image-container {
      display: none;
    }

    .time {
      display: none;
    }
  }
}

//vertical scheduler
.b-scheduler.b-sch-vertical {
  .b-sch-event {
    .image-container {
      display: initial;
    }

    .time {
      display: initial;
    }
  }
}

.b-cust-green {
  &:hover,
  &:focus {
    color: white !important;
  }
}

.scheduler-header {
  color: #fff;
  padding: 0 !important;
  display: initial;
  align-items: center;

  @include breakpoint(xxs) {
    height: 150px;
  }

  #title-container {
  }

  #tools {
    display: flex;
    align-items: center;
    background-color: inherit;
    transition: background-color 0s, margin-top 0.3s;
    padding-right: 1em;
    justify-content: flex-end;

    .datePickerContainer {
      margin: 0 !important;
    }

    .tools-element.date {
      min-width: 156px;

      .b-icon-calendar {
        color: black;
      }
    }

    .tools-element {
      background: white;
      //border: 1px solid #ced4da;
      border-radius: 0.25rem;
      margin-top: 5px;
      margin-bottom: 5px;
      min-width: 250px;
      height: 40px;

      margin-left: 10px;
      margin-right: 10px;

      padding-left: 8px;
      padding-right: 8px;

      .b-field-inner {
        //height: 37px !important;
        border-style: none;
      }

      .b-widget.b-numberfield.b-bright .b-field-inner::before,
      .b-widget.b-textfield.b-bright .b-field-inner::before {
        background-color: transparent;
      }

      .b-widget.b-numberfield.b-bright,
      .b-widget.b-textfield.b-bright {
        border-color: black;
        color: #fff;

        &:hover {
          border-color: black;
        }
      }

      .mat-form-field-infix {
        border-top: 0 !important;
      }

      .mat-form-field-appearance-legacy .mat-form-field-wrapper {
        padding-bottom: 0;
        padding-top: 6px;
      }

      .mat-form-field-appearance-legacy .mat-form-field-underline {
        height: 0 !important;
        display: none !important;
      }

      .mat-form-field-type-mat-select {
        width: 100%;
      }

      #multiselect-checkbox {
        padding-top: 5px;
      }

      .b-pickerfield {
        width: 100% !important;
        margin: 0 !important;
        border: 0 !important;

        label {
          display: none !important;
        }
      }

      span.mat-form-field-label-wrapper {
        display: none;
      }

      .mat-select-value {
        color: black;
      }

      input {
        color: black;
      }
    }
  }
}

.b-sch-style-colored.b-sch-event-wrap .b-sch-event {
  opacity: 1 !important;
}

.b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-sch-event-selected {
  opacity: 1 !important;
}

.b-sch-color-yellow.b-sch-resourcetimerange {
  background-color: rgba(199, 234, 113, 0.2) !important;
  color: #868686 !important;
}

.bryntum-tooltip-info {
  color: $primary-color !important;

  .name {
  }

  .customer-name {
  }

  .phone-number {
  }

  .services {
  }
}

.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: $primary-color !important;
  border-color: transparent;
  color: #fff;
}

.calio {
  &-page-title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  &-page-subtitle {
    font-size: 16px;
    font-weight: 500;
    color: $grey;
  }

  &-form-label {
    color: $primary-color !important;
    font-size: 14px;
    font-weight: 600;
    display: block;
    padding-top: 8px;
  }

  &-sub-form-label {
    color: $primary-color !important;
    font-size: 14px;
    display: block;
    padding-top: 8px;
  }

  &-divider-title {
    font-size: 16px;
    font-weight: 700;
    color: $primary-color;
  }

  &-step-title {
    font-size: 16px !important;
    font-weight: 700;
    color: $primary-color;
  }

  &-step-label {
    font-size: 14px;
    font-weight: 700;
    color: $primary-color;
  }

  &-step-text {
    font-size: 14px;
    font-weight: 600;
    color: $primary-color;
  }
}

/*.list-toolbar-left {
  display: flex;
  //justify-content: flex-start;
  align-items: center;

  @include breakpoint(xxs) {
    width: 100% !important;
    display: block !important;
  }
}

.list-toolbar-right {
  display: flex;
  //justify-content: flex-end;
  align-items: center;

  @include breakpoint(xxs) {
    width: 100% !important;
    display: block !important;
  }
}*/

/*.drop-down-box {
  background-color: white;
  border-radius: 8px;
  height: 40px;
  padding-top: 8px;
  min-width: 92px;
}*/

//style adjustments bryntum scheduler responsiveLevels small
.b-sch-vertical.b-responsive-small {
  .b-resourceheader-cell {
    .b-resource-name {
      display: none;
    }
  }
}

.rounded-circle {
  border: 1px solid #f0f0f0;
}

.dropdown-menu {
  margin-top: 5px !important;
  border: none !important;
  border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -webkit-box-shadow: 0px 0px 64px -26px rgba(0, 0, 0, 0.5) !important;
  -moz-box-shadow: 0px 0px 64px -26px rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0px 0px 64px -26px rgba(0, 0, 0, 0.5) !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff !important;
  border-color: $adminBlue !important;
  background-color: $adminBlue !important;
}

.e-multiselect {
  border: 0 !important;

  &:hover,
  &:focus,
  &:active {
    border: 0 !important;
  }

  &:before {
    //display: none;
    //width: 100%;
  }
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  width: 100% !important;
  display: none !important;
}

@media (-moz-touch-enabled: 1), (pointer: coarse) {
  .e-popup-full-page.e-ddl-device-filter {
    .e-input-group.e-control-wrapper {
      margin-left: 52px !important;
    }
  }
}

.e-datepicker .e-model-header {
  background-color: $violet-color;
  color: #fff;
  cursor: default;
  display: block;
  padding: 10px 10px 10px 15px;
}

.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: $violet-color;
}

.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: $violet-color;
}

/*! datetimepicker theme */
.e-datetime-wrapper .e-input-group-icon.e-icons.e-active {
  color: $violet-color;
}

.e-datetime-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
  color: $violet-color;
}

.e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-calendar .e-content.e-decade td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td.e-selected:hover span.e-day {
  background-color: $violet-color;
}

.e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-calendar .e-content.e-decade td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td.e-selected:hover span.e-day {
  background-color: $violet-color;
}

.e-calendar .e-content td.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  background: none;
  border: 1px solid $violet-color;
  border-radius: 50%;
  color: $violet-color;
}

.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  background: #eee;
  border: 1px solid $violet-color;
  color: $violet-color;
}

.e-calendar .e-content td.e-today:focus span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  color: $violet-color;
}

.e-calendar .e-content td.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day {
  background-color: #eee;
  border: 1px solid $violet-color;
  color: $violet-color;
}

.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day {
  background-color: $violet-color;
  border: 1px solid $violet-color;
  box-shadow: inset 0 0 0 2px #fff;
  color: #fff;
}

.e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected.e-focused-date span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected.e-focused-date span.e-day {
  background-color: $violet-color;
  color: #fff;
}

.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  background-color: $violet-color;
  border: none;
  border-radius: 50%;
  color: #fff;
}

.e-calendar .e-content .e-footer,
.e-bigger.e-small .e-calendar .e-content .e-footer {
  color: $violet-color;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active {
  background-color: #eee;
  color: $violet-color;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active.e-hover {
  background-color: #eee;
  color: $violet-color;
}

.e-btn.e-primary,
.e-css.e-btn.e-primary {
  background-color: $violet-color;
  border-color: transparent;
  color: #fff;
}

.e-btn.e-primary:hover,
.e-css.e-btn.e-primary:hover {
  background-color: $violet-color;
  border-color: transparent;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
}

.e-btn.e-primary:focus,
.e-css.e-btn.e-primary:focus {
  background-color: $violet-color;
  border-color: transparent;
  color: #fff;
  outline: #fafafa 0 solid;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.e-btn.e-primary:active,
.e-css.e-btn.e-primary:active {
  background-color: $violet-color;
  border-color: transparent;
  color: #fff;
  outline: #fafafa 0 solid;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.e-btn.e-primary.e-active,
.e-css.e-btn.e-primary.e-active {
  background-color: $violet-color;
  border-color: transparent;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  color: #fff;
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  background-color: transparent;
  border-color: transparent;
  color: $violet-color;
}

.e-btn.e-flat.e-primary:hover,
.e-css.e-btn.e-flat.e-primary:hover {
  background-color: rgba(227, 22, 91, 0.04);
  border-color: transparent;
  color: $violet-color;
}

.e-btn.e-flat.e-primary:focus,
.e-css.e-btn.e-flat.e-primary:focus {
  background-color: rgba(227, 22, 91, 0.12);
  border-color: transparent;
  color: $violet-color;
}

.e-btn.e-flat.e-primary:active,
.e-btn.e-flat.e-primary.e-active,
.e-css.e-btn.e-flat.e-primary:active,
.e-css.e-btn.e-flat.e-primary.e-active {
  background-color: rgba(227, 22, 91, 0.24);
  border-color: transparent;
  color: $violet-color;
}

.e-btn.e-outline.e-primary,
.e-css.e-btn.e-outline.e-primary {
  background-color: transparent;
  border-color: $violet-color;
  color: $violet-color;
}

.e-btn.e-outline.e-primary:hover,
.e-css.e-btn.e-outline.e-primary:hover {
  background-color: $violet-color;
  border-color: transparent;
  color: #fff;
}

.e-btn.e-outline.e-primary:focus,
.e-css.e-btn.e-outline.e-primary:focus {
  background-color: $violet-color;
  border-color: transparent;
  color: #fff;
}

.e-btn.e-outline.e-primary:active,
.e-btn.e-outline.e-primary.e-active,
.e-css.e-btn.e-outline.e-primary:active,
.e-css.e-btn.e-outline.e-primary.e-active {
  background-color: $violet-color;
  border-color: transparent;
  box-shadow: none;
  color: #fff;
}

/*! timepicker theme */
.e-time-wrapper .e-input-group-icon.e-icons.e-active {
  color: $violet-color;
}

.e-time-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
  color: $violet-color;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active {
  background-color: #eee;
  color: $violet-color;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active.e-hover {
  background-color: #eee;
  color: $violet-color;
}

.e-popup.e-multi-select-list-wrapper .e-list-item.e-active.e-item-focus {
  background-color: #e0e0e0;
  border: 1px solid transparent;
  box-shadow: none;
  color: $violet-color;
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group .e-list-group-item.e-active.e-item-focus {
  background-color: #e0e0e0;
  border: 1px solid transparent;
  box-shadow: none;
  color: $violet-color;
}

input.e-input::selection,
textarea.e-input::selection,
.e-input-group input.e-input::selection,
.e-input-group.e-control-wrapper input.e-input::selection,
.e-float-input input::selection,
.e-float-input.e-control-wrapper input::selection,
.e-input-group textarea.e-input::selection,
.e-input-group.e-control-wrapper textarea.e-input::selection,
.e-float-input textarea::selection,
.e-float-input.e-control-wrapper textarea::selection {
  background: $violet-color;
  color: #fff;
}

::selection {
  background: $violet-color; /* WebKit/Blink Browsers */
  color: #fff;
}

::-moz-selection {
  background: $violet-color; /* Gecko Browsers */
  color: #fff;
}

.cdk-overlay-pane {
  @include breakpoint(xxs) {
    overflow: scroll !important;
  }
}

.create-event-component {
}

.event-step-1 {
  .e-rte-content {
    // min-height: 100px !important;
    margin-top: 30px !important;
    // height: auto !important;
    min-height: 120px !important;
  }
}

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: $primary-color;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 50px;
  z-index: 100;
  opacity: 0;
  transition: all 0.2s ease-in-out;

  .b-fa-arrow-up {
    padding: 5px;
  }

  button {
    background-color: white;
    border-radius: 10px;
    padding: 5px;
    color: $primary-color;
    border-color: $primary-color;
  }
}

.show-scrollTop {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.responsive_table {
  border-radius: 4px;
  background: $white-color;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0.25rem !important;
  //padding-left: 0.25rem !important;
}

@media (max-width: 1199px) {
  .responsive_table {
    overflow-x: auto !important;

    .mat-table {
      min-width: 800px;
    }
  }

  .small_responsive_table {
    overflow-x: auto !important;

    .mat-table {
      min-width: 450px;
    }
  }
}

.card-header {
  .title {
    color: $primary-color !important;
  }
}

.title-box {
  .title {
    color: $primary-color !important;
  }
}

.field-label {
  color: $primary-color !important;
  font-family: "Open Sans", sans-serif !important;
}

.hex-color-code {
  color: $primary-color !important;
}

.hex-color-label {
  color: $primary-color !important;
}

.worker-name,
.category-name,
.heading {
  color: $primary-color !important;
}

label[class^="input-label-"] {
  color: $primary-color !important;
}

app-appointment-services-detail {
  .card-body {
    color: $primary-color !important;
  }
}

app-custom-fields {
  label {
    color: $primary-color !important;
  }
}

.help-text {
  font-size: 12px !important;
  font-weight: 400 !important;

  a {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
}

/*.alert-success {
  background-color: $lightGreen-color;
  color: $green-color;
}*/

.calio-tel-input {
  font-size: 14px !important;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.subnav-button {
  background-color: $white-color !important;
  border-color: $font-color !important;
  color: $font-color !important;
}

.subnav-button:active {
  background-color: $light-sky-blue !important;
  border-color: $font-color !important;
  color: $font-color !important;
}

.subnav-button {
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: $light-sky-blue !important;
    border-color: $font-color !important;
    color: $font-color !important;
  }
}

.selected-subnav-button {
  background-color: $light-sky-blue !important;
}

.dropdown-menu {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

// .subnav-button:hover {
//   background-color: $light-sky-blue !important;
//   border-color: $font-color !important;
//   color: $font-color !important;
// }

input[type="radio"]:hover {
  -webkit-appearance: checkbox;
}

input[type="checkbox"]:hover {
  -webkit-appearance: checkbox;
}

.text-muted {
  font-size: 11px !important;
  font-weight: 400 !important;

  a, a:visited, a:hover {
    font-size: 11px !important;
    font-weight: 400 !important;
  }
}

.small-avatar-choose-employee {
  // height: 35px;
  padding-left: 0px !important;

  .ng-select .ng-select-container {
    border: none !important;
    border-radius: 8px !important;
  }
}

.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option-label {
      a {
        font-size: 13px !important;
      }
    }
  }
}

// full page loader style
.full-page-loader {
  position: fixed;
  background: rgba(0, 0, 0, 0.32);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;

  .loader-container {
    top: 200px;
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
  }

  .mat-spinner {
    width: 50px !important;
    height: 50px !important;

    svg {
      width: 50px !important;
      height: 50px !important;
    }
  }
}

@media (max-width: 500px) {
  .search-box-block {
    width: 100% !important;
  }

  .create-button {
    width: 100% !important;
  }
}

.green-background {
  background: $green-color !important;
  color: white;
}

.yellow-background {
  background: $yellow-color !important;
  color: white;
}

.red-background {
  background: $red-color !important;
  color: white;
}

.clock-icon {
  color: rgba(0, 0, 0, .54) !important;
  cursor: pointer;
}

.vulnerable-error-msg {
  color: $red-color !important;
}


//New calendar

/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
//@import "~@bryntum/calendar/calendar.stockholm.css";
//@import "~@bryntum/demo-resources/scss/example.scss";

.bryntum-calendar-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

bryntum-calendar {
  flex: 1;
}

.b-calendar {
  height: 100%;
}

.b-textfield {
  width: 13em;
}

.b-cal-event {
  transition: opacity 0.4s, box-shadow 0.4s;
}

.b-highlighting {
  .b-cal-event-wrap {
    opacity: 0.3;

    &.b-match {
      opacity: 1;
      outline: 1px dotted red;
      outline-offset: -1px;
      background-color: #d2827640;
      z-index: 10;
    }
  }

  .b-dayview {
    .b-cal-event-wrap.b-match {
      .b-cal-event {
        margin: 1px;
      }
    }
  }
}

.b-dayview-day-container .b-calendar-cell .b-current-time-indicator {
  border-top: 2px solid $red-color;
}

.b-dayview-day-container .b-calendar-cell .b-current-time-indicator:before {
  background-color: $red-color;
}

.b-calendarrow .b-calendar-cell.b-nonworking-day .b-day-name {
  color: $red-color;
}

.b-widget {
  font-family: "Open Sans", sans-serif !important;
}

.b-cal-event-desc {
  font-size: 11px;
}

.b-event-time {
  font-weight: 600;
}

.b-calendarrow .b-cal-cell-header.b-today .b-day-name-date {
  background-color: $violet-color;
  color: $white-color;
}

.b-button label {
  margin-bottom: 0rem !important;
}

.b-custom-event-cal-box {
  display: flex;
  align-items: flex-start;
}

.b-custom-event-image {
  width: 14px;
  margin-top: 1px;
  margin-right: 4px;
}

.b-calendar-tooltip-worker {
  display: flex;
  align-items: center;
  margin-top: 2px;

  .b-calendar-tooltip-worker-image {
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }

  .b-calendar-tooltip-worker-name {

  }
}

.b-calendar-app-services-ul {
  padding-left: 18px !important;
  margin-bottom: 0px;
}

.b-today .cal-agenda-date-date-number {
  background-color: #3a4a66;
  color: #fff;
  padding: 1px 4px 4px 0px;
}

.b-buttongroup .b-button.b-blue.b-pressed, .b-buttongroup .b-pressed.b-button {
  background-color: #3a4a66;
}

.b-sidebar-content .b-datepicker-content .b-calendar-row.b-calendar-week .b-calendar-cell.b-selected-date {
  padding: 0px 1px 0px 0px;
}

.b-calendarrow .b-cal-cell-header.b-today .b-day-name-date {
  background-color: #3a4a66;
  color: white;
}

.b-monthview .b-weeks-container .b-calendar-row .b-week-number-cell.b-today .b-day-num, .b-monthview .b-weeks-container .b-calendar-row .b-calendar-cell.b-today .b-day-num {
  background-color: #3a4a66;
}

.b-hightlight-field {
  border-color: #3b4b66;
  color: #3b4b66;
}

.b-numberfield .b-fieldtrigger:before, .b-textfield .b-fieldtrigger:before {
  // font-size: 1em;
}

.b-stores-combo {
  display: flex;
  flex-direction: column;

  label {
    align-self: start;
    text-transform: none;
  }
}

.b-highlighting .b-cal-event-wrap.b-match {
  outline: none;
  background-color: transparent;
}

.b-button {
  text-transform: none;
}

.hide-event-header .b-event-header {
  display: none;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  padding: 1px 5px 1px 5px !important;
}

.b-combo {
  .b-field-inner {
    min-height: 3em;
  }
}

.b-not-editable {
  &.b-multiselect {
    input {
      display: none;
    }
  }
}

.b-chipview .b-chip {
  display: flex;
  align-items: center;
  margin: 0 0 .3em .3em;
  padding: 0em .5em;
  border-radius: 2px !important;
  background-color: #ebf5ff;
  color: #333;
  transition: background-color .3s, color .3s;
  border: .5px solid #b8dbff !important;
  font-size: 13px !important;
}

.b-chipview .b-chip:hover {
  background-color: #d1e8ff;

  .b-icon {
    background-color: transparent;
  }
}

.b-chipview .b-chip .b-icon {
  background-color: transparent;
  border-radius: 0px;
  border-left: 0px solid #b8dbff;
  padding-left: 5px;
  padding-top: 2px;
}

.b-sidebar-content .b-resourcefilter {
  margin-top: 0em;
}

.b-select-all-resource-checkbox {
  margin-top: 1em;
}

.calenso-dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 4px;
  margin-bottom: 1px;
}

.danger-dot {
  background-color: $red-color;
}

.success-dot {
  background-color: $green-color;
}


// Badge css
.badge-primary {
  color: $white;
  background-color: $primary-color;
}

.badge-danger {
  color: $white;
  background-color: $red-color;
}

.badge-success {
  background-color: $green-color;
  color: $white-color;
  letter-spacing: 0.3px;
}

.badge-violett {
  color: $white;
  background-color: $violet-color;
}

.badge-orange {
  color: $white;
  background-color: $orange;
}

.badge-yellow {
  color: $white;
  background-color: $yellow-color;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: $primary-color;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle, .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: $primary-color;
}

.mat-radio-button .mat-radio-ripple {
  display: none;
}


unblu > div:first-child {
  bottom: 16px !important;
}

.cal-company-link {
  color: $violet-color !important;
}

.cal-dt {
  margin-top: 10px !important;
}

.mat-slide-toggle-bar, .text-muted, p, span, label, h1, h2, h3, h4, h5, h6, small, button, .form-control {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.mat-radio-label {
  white-space: normal !important;
}

// experiment css
.calio-global-search-highlighter {
  background-color: #ffcb3d33;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 p 0px 64px -24px rgba(255, 203, 61, 0.2) !important;
}

.calio-stepper-title {
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin-top: 30px;
}

.calio-stepper-subtitle {
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
  margin-left: 20%;
  margin-right: 20%;
}

.hex-color-code {
  text-transform: uppercase;
}

.cursor-pointer {
  cursor: pointer;
}
