.calio-page-subtitle {
  font-size: 16px;
  color: $gray-color;
  letter-spacing: 0.3px;
}

.calio-action-toolbar {
  .search-box-block {
    width: 50%;
  }

  .right-options-box {
    //width: 100%;
  }

  .actions-box {

  }

  .subnav-button {
    height: 40px;
  }
}

@media (max-width: 991px) {
  .calio-action-toolbar {
    .actions-box {
      width: 100%;
    }

    .search-box-block {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .toolbar-create-button {
    width: 100%;
  }
}


.calio-action-toolbar {
  /*@include breakpoint(xxs) {
    display: block !important;
  }*/
}

